import { BPA } from './BPA';
export const Tool = {
  Locale() {
    return window.vue ? String(window.vue.$i18n.locale).replace(/_/g, '-') : 'en-GB';
  },
  DateFormat(date, locale = this.Locale(), options) {
    if (!date) return null;
    date = new Date(date + '+0000');
    if (!options) options = {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      //hour12: false,
      hourCycle: 'h23'
    }
    let formatter = new Intl.DateTimeFormat(locale, options);
    let date_format = formatter.format(date);
    let parts = formatter.formatToParts(date);
    let time_parts = parts.slice(Math.max(parts.length - 5, 0));
    time_parts.slice(0).reverse().map(({type}, index) => {
      if (type == 'literal') parts.slice(0).reverse()[index].value = ':';
    });
    date_format = parts.map(({value}) => value).join('');
    return date_format;
  },
  DateRange(props = {}, locale = this.Locale()) {
    let date = null;
    let string = '';
    let start = null;
    let end = null;
    let parts = {};
    let ltz = '+0000';
    let options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    };
    if (props.time) {
      options = {...options, ...{
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }};
    }
    if (props.day) {
      options.weekday = 'short';
    }
    date = new Intl.DateTimeFormat(locale, options);
    if (props.start && props.end) {
      start = new Date(props.start + ltz);
      end = new Date(props.end + ltz);
      string = date.formatRange(start, end);
    } else if (props.start && !props.end) {
      start = new Date(props.start + ltz);
      string = date.format(start);
    }
    parts.start = {array: date.formatToParts(start)};
    if (end) parts.end = {array: date.formatToParts(end)};
    for (let type in parts) {
      parts[type].date = '';
      parts[type].time = '';
      let part = 'date';
      for (let unit of parts[type].array) {
        if (unit.type == 'hour') part = 'time';
        parts[type][part] += unit.value;
      }
      parts[type].date = parts[type].date.trim();
      parts[type].time = parts[type].time.replace(/\./g, ':');
    }
    string = string.replace(/–/, ' - ');
    return {string, parts};
  },
  /*
  DateOnly(date) {
    date = date + ' 00:00:00';
    date = this.DateFormat(date).replace(this.TimeOnly(date), '');
    return date.replace(/,(?=[^,]*$)/, '');
  },
  */
  DateOnly(date, locale = this.Locale()) {
    if (date) return this.DateFormat(date, locale, {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });
  },
  TimeOnly(date, locale = this.Locale()) {
    if (date) return this.DateFormat(date, locale, {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hourCycle: 'h23'
    });
  },
  DateString(date = new Date(date)) {
    return date.getFullYear() + '-' + this.pad(date.getMonth() + 1) + '-' + this.pad(date.getDate());
  },
  DateToUTC(date) {
    return new Date(date).toISOString().replace('T', ' ').substring(0, 19);
  },
  DateToISO(date, local_time_zone = false) {
    date = new Date(date + (local_time_zone ? '+0000' : ''));
    const YYYY = date.getFullYear().toString();
    const MM = this.pad(date.getMonth() + 1);
    const DD = this.pad(date.getDate());
    const hh = this.pad(date.getHours());
    const mm = this.pad(date.getMinutes());
    const ss = this.pad(date.getSeconds());
    return YYYY + '-' + MM + '-' + DD + ' ' + hh + ':' + mm + ':' + ss;
  },
  DateToLTZ(utc_date) {
    return new Date(utc_date).toISOString().replace('T', ' ').slice(0, -5);
  },
  DateOfWeek(week, year = new Date().getFullYear(), leap = 0) {
    return new Date(year, 0, (1 + (week - leap) * 7));
  },
  WeekNumber(date) {
    date = new Date(date);
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    const year = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    return Math.ceil((((date - year) / 86400000) + 1) / 7);
  },
  WeeksInYear(year) {
    const date = new Date(year, 11, 31);
    const week = this.WeekNumber(date);
    return week == 1 ? 52 : week;
  },
  DateTimeDiff(start, end) {
    return (new Date(end) - new Date(start)) / 1000;
  },
  TimeFormat(secs = 0, all_parts = false, colon_divided = false) {
    let total = secs;
    let hours = Math.floor(total / 3600);
    total = Math.floor(total % 3600);
    let minutes = Math.floor(total / 60);
    let seconds = total % 60;
    const parts = [hours, minutes, seconds];
    const time = [];
    parts.map((part, i) => {
      const unit = i == 0 ? 'h' : i == 1 ? 'm' : 's';
      if (part > 0 || parts[i - 1] > 0 || all_parts) {
        time[i] = this.pad(part) + unit;
      }
    });
    let format = time.join(',').replace(/,/g, ' ').trim();
    if (colon_divided) {
      format = format.replace(/^0|[a-z]/g, '').replace(/\s/g, ':');
    }
    return format;
  },
  TimeZone(country_code = '') {
    let language = new Intl.Locale(country_code);
    console.log(language)
    
  },
  NumberFormat(number = 0, locale = this.Locale()) {
    return new Intl.NumberFormat(locale).format(number);
  },
  CurrencyFormat(number = 0, locale, minmax) {
    locale = locale || this.Locale();
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: minmax || 2, 
      maximumFractionDigits: minmax || 2
    }).format(number);
  },
  DisplayName(type = '', code = '', locale) {
    if (window.Intl && Intl.DisplayNames) {
      locale = locale || this.Locale();
      if (locale) locale = locale.split('-')[0];
      if (type != 'language') code = String(code).toUpperCase();
      if (!code) return;
      return this.Capitalize(new Intl.DisplayNames(locale || 'en', {type}).of(code));
    }
    console.info('Intl.DisplayNames is not supported');
    return '';
  },
  CurrencyName(currency_code = '', locale) {
    return this.DisplayName('currency', currency_code, locale);
  },
  CountryName(country_code = '', locale) {
    return this.DisplayName('region', country_code, locale);
  },
  LanguageName(country_code = '', locale) {
    return this.DisplayName('language', country_code, locale);
  },
  pad(number) {
    return number < 10 ? '0' + number : number;
  },
  pad2(string) {
    return String(string).padStart(2, '0');
  },
  IsTruncated(element = HTMLElement) {
    let range = new Range();
    range.selectNodeContents(element);
    let rect = {range, element}
    for (let client in rect) {
      rect[client] = rect[client].getBoundingClientRect();
    }
    if (rect.range.right > rect.element.right ) return true;
  },
  IsJsonString(string) {
    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }
    return true;
  },
  IsHtmlString(string) {
    try {
      let dom = new DOMParser().parseFromString(string, 'text/html');
      return dom.body.children.length > 0;
    } catch(e) {
      return false;
    }
  },
  UnescapeJson(string) {
    let json = JSON.parse(string);
    if (typeof json == 'string') {
      json = JSON.parse(json);
    }
    return json;
  },
  SerializeFormData(form) {
    let raw = new FormData(form);
    let data = {};
    for(let pair of raw.entries()) {
      data[pair[0]] = pair[1];
    }
    return data;
  },
  Capitalize(string = '') {
    string = String(string);
    return string.charAt(0).toUpperCase() + string.substring(1);
  },
  TitleCase(string = '', split = ' ') {
    let words = String(string).trim().split(split);
    for (let i = 0; i < words.length; i++) {
      words[i] = this.Capitalize(words[i]);
      if (words[i].includes('-')) {
        words[i] = this.TitleCase(words[i], '-');
      }
      if (/[.]\D/g.test(words[i])) {
        words[i] = this.TitleCase(words[i], '.');
      }
    }
    return words.join(split);
  },
  Alphabetize(list, prop) {
    return list.sort((a, b) => {
      a = String(a[prop]).toUpperCase();
      b = String(b[prop]).toUpperCase();
      return a < b ? -1 : a > b ? 1 : 0;
    });
  },
  Siblings(element) {
    const siblings = [];
    if (!element.parentNode) return siblings;
    let sibling = element.parentNode.firstChild;
    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== element) {
        siblings.push(sibling);
      }
      sibling = sibling.nextSibling;
    }
    return siblings;
  },
  PreviousSiblings(element) {
    const siblings = [];
    while ((element = element.previousElementSibling)) {
      siblings.push(element);
    }
    return siblings;
  },
  NextSiblings(element) {
    const siblings = [];
    while ((element = element.nextElementSibling)) {
      siblings.push(element);
    }
    return siblings;
  },
  Group(array, size) {
    const groups = [];
    for (let i = 0; i < array.length; i += size) {
      let group = array.slice(i, i + size);
      groups.push(group);
    }
    return groups;
  },
  Empty(element) {
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
    return element;
  },
  Style(element, property) {
    if (!element) return;
    let style = window.getComputedStyle(element);
    if (!property) {
      let styles = {};
      for (let prop of Object.values(style)) {
        let key = prop.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
        if (/^([A-Z])/.test(key)) key = key.charAt(0).toLowerCase() + key.substring(1);
        if (key !== '') styles[key] = style.getPropertyValue(prop);
      }
      return styles;
    }
    return style.getPropertyValue(property);
  },
  CloneObject(object = {}) {
    return JSON.parse(JSON.stringify(object));
    //return Object.assign({}, object);
    //return {...object};
  },
  async CopyToClipboard(string, persistent) {
    return await new Promise(resolve => {
      let textarea = document.createElement('textarea');
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.value = string;
      textarea.select();
      document.execCommand('copy');      
      document.body.removeChild(textarea);
      if (persistent) {
        BPA.storage.setItem('clipboard', string);
      }
      resolve(string);
    });
  },
  async PasteFromClipboard(purge) {
    return await new Promise(resolve => {
      let string = BPA.storage.getItem('clipboard') || '';
      if (purge) BPA.storage.removeItem('clipboard');
      document.execCommand('paste');
      resolve(string);
    });
  },
  RandomString(length = 5) {
    const result = [];
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
    }
    return result.join('');
  },
  ByteSize(string = '') {
    return new Blob([string]).size;
  },
  BytesToSize(bytes = 0, decimals = 2) {
    if (bytes == 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  },
  IsValidURL(string = '') {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  },
  IsValidRegex(string = '') {
    try {
      new RegExp(string);
    } catch (e) {
      return false;
    }
    return true;
  },
  Hyperlink(props = {}) {
    let element = document.createElement('a');
    for (let prop in props) {
      if (!props[prop]) {
        delete props[prop];
        if (prop == 'href') {
          delete props.target;
        }
      }
      if (prop == 'href') {
        if (!('text' in props)) {
          if ('html' in props) {
            element.innerHTML = props.html;
          } else {
            element.text = props.href;
          }
        }
        if ('scheme' in props) {
          let scheme = props.scheme;
          let href = props.href;
          switch (scheme) {
            case 'google':
              scheme = '//google.com/search?q=';
              break;
            case 'map':
              scheme = '//google.com/maps/search/';
              href = href.replace(/\s/g, '+');
              break;
            case 'internal':
              scheme = window.location.origin + '/';
              break;
            default:
              scheme = scheme + ':';
          }
          props.href = scheme + href;
        }
      }
    }
    for (const prop in props) {
      element[prop] = props[prop];
    }
    if (element.hasAttribute('href')) {
      element.classList.add('hyperlink');
    }
    if (!element.attributes.length) {
      const textContent = element.textContent;
      element = document.createElement('span');
      element.textContent = textContent;
    }
    element.setAttribute('draggable', false);
    element.setAttribute('onclick', 'if (window.getSelection().getRangeAt(0).cloneContents().textContent) return false;');
    return element.outerHTML;
  },
  TrackingURL(params = {}) {
    if (!Object.keys(params).length) return;
    if (params.agent && params.code) {
      const lang = params.lang || document.documentElement.lang;
      const agent = params.agent;
      const code = params.code;
      const zip_code = params.zip_code 
      let url = 'https://';
      switch (agent) {
        case 'bring':
          switch (lang) {
            case 'no':
              url += 'sporing.posten.no/sporing';
              break;
            case 'sv':
              url += 'tracking.bring.se/tracking';
              break;
            case 'da':
              url += 'tracking.bring.dk/tracking';
              break;
            default:
              url += 'tracking.bring.com/tracking';
          }
          url += '/' + code;
          break;
        case 'burd':
          url += 'burd.dk/pages/track-and-trace?q=' + code;
          break;
        case 'dao':
          switch (lang) {
            case 'da':
              url += 'dao.as/privat/find-din-pakke';
              break;
            default:
              url += 'dao.as/en/private/track-your-parcel';
          }
          url += '?stregkode=' + code;
          break;
        case 'dhl':
          url += 'dhl.com/';
          switch (lang) {
            case 'da':
              url += 'dk-da/home/tracking';
              break;
            case 'de':
              url += 'de-de/home/sendungsverfolgung';
              break;
            case 'no':
              url += 'no-no/home/sporing';
              break;
            case 'se':
              url += 'se-sv/home/sparning';
              break;
            default:
              url += 'gb-en/home/tracking';
          }
          url += '.html?tracking-id=' + code + '&submit=1';
          break;
        case 'gls':
          switch (lang) {
            case 'da':
              url += 'gls-group.eu/DK/da/find-pakke';
              break;
            case 'de':
              url += 'gls-pakete.de/sendungsverfolgung';
              break;
            default:
              url += 'gls-group.eu/GROUP/en/parcel-tracking';
          }
          url += '?match=' + code + '&postalCode=' + zip_code;
          break;
        case 'instabox': {
          let locale = '';
          url += 'track.instabox.io';
          switch (lang) {
            case 'no':
              locale = 'no-no';
              break;
            case 'sv':
              locale = 'se-sv';
              break;
            case 'da':
              locale = 'dk-da';
              break;
            default:
              locale = 'dk-en';
          }
          url += '/' + locale + '/' + code + '?c=' + zip_code;
          break;
        }
        case 'pdk':
          switch (lang) {
            case 'no':
              url += 'postnord.no/pakkesporing?shipmentId=';
              break;
            case 'sv':
              url += 'postnord.se/vara-verktyg/spara-brev-paket-och-pall?shipmentId=';
              break;
            case 'de':
              url += 'tracking.dpd.de/status/de_DE/parcel/';
              break;
            case 'da':
              url += 'postnord.dk/varktojer/track-trace?shipmentId=';
              break;
            default:
              url += 'postnord.dk/en/tools/track-and-trace?shipmentId=';
          }
          url += code;
      }
      if (params.tracking) {
        let dev = process.env.VUE_APP_DEV;
        let host = window.location.origin;
        let company = params.company;
        const companies = JSON.parse(BPA.storage.getItem('BPA-Companies'));
        if (!isNaN(company)) company = (companies.find(o => o.id == company) || {}).code;
        if (!dev) host = 'https://' + company + '.packship.eu';
        url = host + '/tracking/?lang=' + lang + '&agent=' + agent + '&code=' + code;
      }
      return url;
    }
  },
  Webshop(company_id, increment_id) {
    if (!(company_id && increment_id)) {
      return {url: '', language: '', country: ''};
    }
    let company_code = BPA.company('code', company_id);
    let webshop = {url: `https://${company_code}.`, language: '', country: ''};
    let increment_code = increment_id.substring(0, 2);
    switch (company_code) {
      case 'beautycos': {
        switch (increment_code) {
          case "90": {
            webshop.url += "fr";
            webshop.language = "fr";
            webshop.country = "fr";
            break;
          }
          case "55": {
            webshop.url += "no";
            webshop.language = "no";
            webshop.country = "no";
            break;
          }
          case "44": {
            webshop.url += "se";
            webshop.language = "sv";
            webshop.country = "se";
            break;
          }
          case "33": {
            webshop.url += "de";
            webshop.language = "de";
            webshop.country = "de";
            break;
          }
          case "22": {
            webshop.url += "co.uk";
            webshop.language = "en";
            webshop.country = "gb";
            break;
          }
          case "18": {
            webshop.url += "at";
            webshop.language = "de";
            webshop.country = "at";
            break;
          }
          case "17": {
            webshop.url += "ie";
            webshop.language = "en";
            webshop.country = "ie";
            break;
          }
          case "16": {
            webshop.url += "be/nl";
            webshop.language = "nl";
            webshop.country = "be";
            break;
          }
          case "15": {
            webshop.url += "be/fr";
            webshop.language = "fr";
            webshop.country = "be";
            break;
          }
          case "14": {
            webshop.url += "pl";
            webshop.language = "pl";
            webshop.country = "pl";
            break;
          }
          case "13": {
            webshop.url += "nl";
            webshop.language = "nl";
            webshop.country = "nl";
            break;
          }
          case "12": {
            webshop.url += "fi";
            webshop.language = "fi";
            webshop.country = "fi";
            break;
          }
          case "11": {
            if (increment_id.length == 9) {
              webshop.url += "dk";
              webshop.language = "da";
              webshop.country = "dk";
              break;
            } else {
              webshop.url += "it";
              webshop.language = "it";
              webshop.country = "it";
              break;
            }
          }
          case "10": {
            webshop.url += "es";
            webshop.language = "es";
            webshop.country = "es";
          }
        }
        break;
      }
      case 'klubklub': {
        switch (increment_code) {
          case '20': {
            webshop.url += 'co.uk';
            webshop.language = 'en';
            webshop.country = 'gb';
            break;
          }
          case '10': {
            webshop.url += 'dk';
            webshop.language = 'da';
            webshop.country = 'dk';
          }
        }
      }
    }
    return webshop;
  },
  PrinterOptions(printers = [], type = '') {
    let options = [];
    let not_filters = [];
    let filter_test = true;
    let opposite_type = '';
    let opposite_filter = null;
    let filters = BPA.printer.fetch('filter') || {};
    type = type.toLowerCase().replace(/\s/g, '').trim();
    if (type) {
      let filter = filters[type] || [];
      not_filters = filter.filter(filter => /^not(_|\s)/i.test(filter));
      if (not_filters.length) {
        opposite_type = type == 'a4' ? 'label' : 'a4';
        opposite_filter = filter.find(filter => new RegExp(`^not(_|\\s)${opposite_type}$`, 'i').test(filter));
      }
      if (opposite_filter) {
        not_filters = not_filters.filter(filter => filter != opposite_filter).map(filter => filter.replace(/^not(_|\s)/i, ''));
        if (filters[opposite_type]) {
          if (not_filters.length) {
            filters[opposite_type] = [...filters[opposite_type], ...not_filters];
          }
          filters[opposite_type] = new RegExp(`${filters[opposite_type].join('|')}`, 'i');
        } else {
          filters[opposite_type] = new RegExp();
        }
      } else {
        not_filters = not_filters.map(filter => filter.replace(/^not(_|\s)/i, ''));
        if (not_filters.length) {
          filters[type] = new RegExp(`${not_filters.join('|')}`, 'i');
        } else {
          filters[type] = new RegExp(`${filter.join('|')}`, 'i');
        }
      }
      if (!(type in filters)) filters[type] = new RegExp();
    }
    for (let printer of printers) {
      let printer_name = String(printer.name);
      let printer_description = String(printer.description);
      let name = printer_name.toLowerCase().replace(/_/g, ' ').trim();
      let description = printer_description.toLowerCase().replace(/_/g, ' ').trim();
      let label = `[${printer.id}] ${printer.computer} - ${!new RegExp(name, 'i').test(description) && !new RegExp(description, 'i').test(name) ? `${printer_name} "${printer_description}"` : printer_name}`;
      if (type) {
        if (opposite_filter) {
          filter_test = !filters[opposite_type].test(label);
        } else {
          if (not_filters.length) {
            filter_test = !filters[type].test(label);
          } else {
            filter_test = filters[type].test(label);
          }
        }
      }
      if (filter_test) {
        options.push({...printer, ...{label: label, title: `${label} (${printer.state})`, color: printer.state == 'connected' ? 'green' : 'red'}});
      }
    }
    return this.Alphabetize(options, 'state');
  },
  ConvertEmojisToUnicode(string = '') {
    let emoji = /(\p{EPres}|\p{ExtPict})(\u200d(\p{EPres}|\p{ExtPict}))*/gu;
    return string.replace(emoji, (m) => `&#x${m.codePointAt(0).toString(16)};`);
  },
  ParseMarkDown(string = '') {
    // ul
    string = string.replace(/^\s*\n\*/gm, '<ul>\n*');
    string = string.replace(/^(\*.+)\s*\n([^*])/gm, '$1\n</ul>\n\n$2');
    string = string.replace(/^\*(.+)/gm, '<li>$1</li>');
    // ol
    string = string.replace(/^\s*\n\d\./gm, '<ol>\n1.');
    string = string.replace(/^(\d\..+)\s*\n([^\d.])/gm, '$1\n</ol>\n\n$2');
    string = string.replace(/^\d\.(.+)/gm, '<li>$1</li>');
    // blockquote
    string = string.replace(/^>(.+)/gm, '<blockquote>$1</blockquote>');
    // h
    string = string.replace(/[#]{6}(.+)/g, '<h6>$1</h6>');
    string = string.replace(/[#]{5}(.+)/g, '<h5>$1</h5>');
    string = string.replace(/[#]{4}(.+)/g, '<h4>$1</h4>');
    string = string.replace(/[#]{3}(.+)/g, '<h3>$1</h3>');
    string = string.replace(/[#]{2}(.+)/g, '<h2>$1</h2>');
    string = string.replace(/[#]{1}(.+)/g, '<h1>$1</h1>');
    // alt h
    string = string.replace(/^(.+)\n=+/gm, '<h1>$1</h1>');
    string = string.replace(/^(.+)\n-+/gm, '<h2>$1</h2>');
    // images
    string = string.replace(/!\[([^\]]+)\]\(([^)]+)\)/g, '<img src="$2" alt="$1" />');
    // links
    string = string.replace(/[[]{1}([^\]]+)[\]]{1}[(]{1}([^)"]+)("(.+)")?[)]{1}/g, '<a href="$2" title="$4">$1</a>');
    // font styles
    string = string.replace(/[*_]{2}([^*_]+)[*_]{2}/g, '<b>$1</b>');
    string = string.replace(/[*_]{1}([^*_]+)[*_]{1}/g, '<i>$1</i>');
    string = string.replace(/[~]{2}([^~]+)[~]{2}/g, '<del>$1</del>');
    // pre
    string = string.replace(/^\s*\n```(([^\s]+))?/gm, '<pre class="$2">');
    string = string.replace(/^```\s*\n/gm, '</pre>\n\n');
    // code
    string = string.replace(/[`]{1}([^`]+)[`]{1}/g, '<code>$1</code>');
    // p
    //string = string.replace(/^\s*(\n)?(.+)/gm, m => /<(\/)?(h\d|ul|ol|li|blockquote|pre|img)/.test(m) ? m : `<p>${m.replace(/^\n+/, '')}</p>`);
    // strip p from pre
    string = string.replace(/(<pre.+>)\s*\n<p>(.+)<\/p>/gm, '$1$2');
    return string;
  },
}